/**
 * Utility library.
 */

/**
 * Exteding the includes function in a String
 * @return {Boolean} - if the String contains the sent argument String
 */

String.prototype.contains = function (subString) {
    return this.indexOf(subString) != -1;
};

Array.prototype.containsEl = function (obj) {
    var i = this.length;
    while (i--) {
        if (this[i] == obj) {
            return true;
        }
    }
    return false;
}



/**
 * isSmallScreen - Determine if screen is 'small' (less than 768px)
 * @return {Boolean} - describing if screen is 'small'
 */
function isSmallScreen() {
    /**
     * TODO: add breakpoint arg
     */
    return window.innerWidth < 768;
}

function isMobileOrTabletScreen() {
    /**
     * TODO: add breakpoint arg
     */
    return window.innerWidth < 1025;
}

function isTouch() {
    return document.getElementsByTagName('html')[0].classList.contains('touch') ||
        // fallback if Modernizr is removed
        'ontouchstart' in document.documentElement;
}

/**
 * isMobile - Determines if device is 'mobile' based on if screen is
 * 			smaller than 768px and has touch capabilities
 * @return {Boolean} - describing if this device is 'mobile' or not
 */
function isMobile() {
    /**
     * TODO: This NEEDS to be more intelligent!!
     * - add breakpoint arg and default to pass to isSmallScreen()
     */
    return isSmallScreen() && isTouch();
}

/**
 * allowsAmbientVideo - Indicate whether this browser/device allows ambient video
 * to be played. Currently we disallow ambient video on touch devices since it's
 * better to be safe than sorry. This penalizes things like Surface Pro but this
 * is okay since ambient video is icing on the cake.
 * @return {Boolean} Does device allow ambient video?
 */
function allowsAmbientVideo() {
    return !isTouch();
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

module.exports = {

    brands: {
        PULTE: 1,
        CENTEX: 2,
        DELWEBB: 3,
        DIVOSTA: 4,
        JW: 5,
        AMERICANWEST: 6
    },
    // match css breakpoints
    breakpoints: {
        // Dan commented out because we probably won't use this in javascript
        // if you need this value we need to add code to cloudinary to not add
        // this value to the hash in responsive-mediator.js
        // xs: 320,
        sm: 768,
        md: 1024,
        lg: 1600
    },
    getCookie: getCookie,
    setCookie: setCookie,
    has: {
        // Modernizr
        touch: isTouch,
        ambientVideo: allowsAmbientVideo
    },

    is: {
        smallScreen: isSmallScreen,
        mobileOrTabletScreen: isMobileOrTabletScreen,
        mobile: isMobile
    },

    // http://davidwalsh.name/javascript-debounce-function
    debounce: function (func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },
    // https://remysharp.com/2010/07/21/throttling-function-calls
    throttle: function (fn, threshhold, scope) {
        threshhold || (threshhold = 250);
        var last,
            deferTimer;
        return function () {
            var context = scope || this;

            var now = +new Date,
                args = arguments;
            if (last && now < last + threshhold) {
                // hold on to it
                clearTimeout(deferTimer);
                deferTimer = setTimeout(function () {
                    last = now;
                    fn.apply(context, args);
                }, threshhold);
            } else {
                last = now;
                fn.apply(context, args);
            }
        };
    },

    /**
   * getUrlParameters - Return all tha values for the sent parameter
   * @return {String} - With all the values for that parameter
   */
    getUrlParameters: function (urlParameter) {
        var results = new RegExp('[\?&]' + urlParameter + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        }
        else {
            return results[1] || 0;
        }
    },

    //https://css-tricks.com/snippets/jquery/get-query-params-object/
    getQueryParams: function (str) {
        return (str || document.location.search)
            .replace(/(^\?)/, '')
            .split("&")
            .map(function (n) { return n = n.split("="), this[n[0]] = n[1], this }.bind({}))[0];
    },

    //See if a certain hash value is in the URL
    hasHashValue: function (value) {
        var returnValue = false;
        if (window.location.hash != '') {
            var hash = (location.href.split("#")[1] || "").toLowerCase();
            if (hash == value) {
                returnValue = true;
            }
        }
        return returnValue;
    },

    //Use this to set the video protocol to http or https, depending on the current
    //document protocol behrreich 20161205 114805
    changeVideoProtocol: function (elem) {
        var items = $(elem.currentTarget).find('.owl-item');
        $(items).each(function (index, elem) {
            //Fix the video Url and Thumbnail Url
            if ($(elem).data('video') != undefined) {
                var videoThumbnailUrl = $(elem).find('.owl-video-tn')[0].style.backgroundImage;
                $(elem).find('.owl-video-tn')[0].style.backgroundImage = videoThumbnailUrl.replace('http://', 'https://');

                var url = document.location.protocol + $(elem).data('video').replace('http:', '').replace('https:', '');
                $(elem).data('video', url);
                $(elem).find('a.owl-video').attr('href', url);
            }
        });
    },

    getBrand: function (brandString) {
        _this = this;
        this.enumBrand = '';

        formattedBrand = brandString.toLowerCase().replace(/-|\s/g, '');
        switch (formattedBrand) {
            case 'pulte':
                _this.enumBrand = _this.brands.PULTE;
                break;
            case 'centex':
                _this.enumBrand = _this.brands.CENTEX;
                break;
            case 'delwebb':
                _this.enumBrand = _this.brands.DELWEBB;
                break;
            case 'divosta':
                _this.enumBrand = _this.brands.DIVOSTA;
                break;
            case 'jw':
                _this.enumBrand = _this.brands.JW;
                break;
            case 'americanwest':
                _this.enumBrand = _this.brands.AMERICANWEST;
                break;
            default:
                _this.enumBrand = _this.brands.PULTE;
        }

        return this.enumBrand;
    },

    formatPhoneNumber: function (phoneNumber) {
        var x = phoneNumber.match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,30})/);
        formattedPhone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] + (x[4] ? x[4] : '') : '');
        return formattedPhone;
    },

    formatDirtyPhoneNumber: function (dirtyPhoneNumber) {
        var cleanPhoneNumber = dirtyPhoneNumber.replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(' ', '');

        return this.formatPhoneNumber(cleanPhoneNumber);
    },

    formatPrice: function (price, includeCents, includeDollarSign) {
        var dollarSign = includeDollarSign ? '$' : '';
        var decimals = includeCents ? 2 : 0;

        return dollarSign + parseFloat(price).toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },

    isBelowViewport: function ($elem) {
        var scrolledPlusViewable = $(window).scrollTop() + $(window).height() + 200; // added 200 pixels so events can trigger before element is seen by user

        if (scrolledPlusViewable < $elem.offset().top) {
            return true;
        } else {
            return false;
        }
    },

    generateGuid: function () {
        function _p8(inMiddle) {
            var randomKeySet = (Math.random().toString(16) + "000000000").substr(2, 8);
            return inMiddle ? "-" + randomKeySet.substr(0, 4) + "-" + randomKeySet.substr(4, 4) : randomKeySet;
        }

        return _p8() + _p8(true) + _p8(true) + _p8();
    },

    // To handle when a particular element becomes visible within the viewport.
    handleVisible: function ($elem, callback) {
        if ($elem && $elem.length && callback) {
            var _this = this;
            // Check if element is initially viewable in viewport already.
            // If not, create scroll handler to perform callback for when it's scrolled into view.
            if (_this.isVisibleInViewport($elem)) {
                callback();
            }
            else {
                var scrollHandler = this.debounce(function () {
                    if (_this.isVisibleInViewport($elem)) {
                        callback();
                        $(window).off("scroll", scrollHandler);
                    }
                }, 20, true);
                $(window).scroll(scrollHandler);
            }
        }
    },

    isVisibleInViewport: function ($elem) {
        var _window = $(window);
        var docViewTop = _window.scrollTop();
        var docViewBottom = docViewTop + _window.height();
        var elemTop = $elem.offset().top + ($elem.height() / 2);
        return ((elemTop <= docViewBottom) && (elemTop >= docViewTop));
    },

    scrollToElement: function ($elem) {
        if ($elem && $elem.length) {
            var offset = $elem.offset();

            if (offset && offset.top >= 0) {
                var $page = $("html, body");

                // if user scrolls while animation is still in progress, cancel the animation and give control back to the user
                $page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function () {
                    $page.stop();
                });

                // scroll to the specified element and hide the global header
                $page.animate({
                    scrollTop: offset.top
                }, 1000, 'swing', function () {
                    $(window).trigger('Header.hide');
                    $page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
                });
            }
        }
    },
    scrollToTarget: function (element, offset, rate) {
        if (element) {
            if (offset === undefined || isNaN(offset)) {
                offset = 100;
            }

            if (rate === undefined || isNaN(rate)) {
                rate = 500;
            }

            var boundingClientRect = element.getBoundingClientRect();
            var calculatedTop = boundingClientRect.top + window.scrollY - offset;
            var currentTop = calculatedTop;
            var left = boundingClientRect.left

            // Page will scroll to initial calculated position of the target element
            window.scroll({
                top: calculatedTop,
                left: left,
                behavior: "smooth"
            });

            // While the page scrolls, images will load in which will push the target element further down the page
            // This setInterval will recalculate for the target element's position until the page finally scrolls to the element's final position
            var intervalId = setInterval(function () {
                boundingClientRect = element.getBoundingClientRect();
                calculatedTop = boundingClientRect.top + window.scrollY - offset;

                if (currentTop !== calculatedTop) {
                    window.scroll({
                        top: calculatedTop,
                        left: left,
                        behavior: "smooth"
                    });

                    currentTop = calculatedTop;
                }
                else if (currentTop === calculatedTop) { // When the correct position for the target element is finally reached, this interval will be cleared
                    clearInterval(intervalId);
                }
            }, rate);
        }
    },

    getMonthName: function (monthIndex) {
        if (monthIndex > -1 && monthIndex < 12) {
            var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            return monthNames[monthIndex];
        }
        return null;
    },

    pushToDataLayer: function (eventObject) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eventObject);
    }
};


