var component = require('../../../lib/js/component.js');
var utils = require('../../../lib/js/utils.js');
var validator = require('./validator.js');
var constants = require('./constants.js');

var FieldGroupPhone = function () {

    this.initialize = function (el, options) {
        this.options = options || {};
        this.data = this.$element.data();

        var fieldSelectors = ['.Field-phone'];
        this.$element.on(constants.FIELD_INIT, fieldSelectors.join(), this.handleFieldInit.bind(this));
        this.$element.on(constants.FIELD_CHECKED, fieldSelectors.join(), this.handleFieldCheck.bind(this));

        this.$toggle = this.find('.CheckboxControl input');
        this.$element.on('change', '.CheckboxControl', this.handleToggleChange.bind(this));

        this.trigger(constants.FIELD_INIT, this);
    };

    this.handleFieldInit = function (event, field) {
        this.$phoneNumber = field;
        this.$phoneNumberField = this.$phoneNumber.$element.find('[name="Phone"]');
        this.$phoneNumberField.on('input', this.handleInput.bind(this));
        if(Pulte.user && Pulte.user.OutsideUS === "True"){
            this.$phoneNumber.$element.attr('data-validate', 'itel');
            this.$phoneNumberField.attr('type', 'itel');
            this.$phoneNumberField.removeClass("tel").addClass("itel");
        }
        this.handleInput();

        event.stopPropagation();
    };

    this.handleFieldCheck = function (event) {
        event.stopPropagation();
        this.trigger(constants.FIELD_CHECKED, this);
    };

    this.handleToggleChange = function (event) {
        event.stopPropagation();
        if (this.toggleChecked()) {
            this.$phoneNumber.$element.attr('data-validate', 'itel');
            this.$phoneNumberField.attr('type', 'itel');
            this.$phoneNumberField.removeClass("tel").addClass("itel");
        } else {
            this.$phoneNumber.$element.attr('data-validate', 'tel');
            this.$phoneNumberField.attr('type', 'tel');
            this.$phoneNumberField.removeClass("itel").addClass("tel");
        }
        // form validation needs to be updated
        this.handleInput();
        this.trigger(constants.FIELD_CHECKED, this);
        this.$phoneNumber.setErrorClass(this.isValid());
    };

    this.isValid = function (event) {
        return this.$phoneNumber.isValid();
    };

    this.appendData = function (data) {
        var phoneNumberValue = this.$phoneNumber.getValue();
        if(phoneNumberValue.length >= 14 && phoneNumberValue.substr(0, 1) === '(' && phoneNumberValue.substr(4, 2) === ') ' 
            && phoneNumberValue.substr(9, 1) === '-') {
                phoneNumberValue = phoneNumberValue.substr(1, 3) + phoneNumberValue.substr(6, 3) + phoneNumberValue.substr(10, 4);
            }
        data[this.$phoneNumber.getName()] = phoneNumberValue;
        return data;
    };

    this.toggleChecked = function () {
        return this.$toggle.is(':checked');
    };

    this.handleInput = function(e) {
        if(!this.toggleChecked()) {
            var x = this.$phoneNumberField.val().replace(/\D/g, '');
            this.$phoneNumberField.val(utils.formatPhoneNumber(x));
        } else {
            var y = this.$phoneNumberField.val().replace(/\D/g, '').match(/(\d{0,40})/);
            this.$phoneNumberField.val(y[1]);
        }
    }
};

module.exports = component(FieldGroupPhone);
