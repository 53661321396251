var component = require('../../../lib/js/component.js');
var moreToggle = require('../../../templates/shared/more-links.js');

function HomeDesignSummary() {

    var $container = $(".HomeDesignSummary__section-container");
    
    var viewModel = function() {
        var self = this;
        self.viewObservable = {
            exactFilteredCount: ko.observable(0),
            closeFilteredCount: ko.observable(0)
        } 
    }

    this.koModel = {};

    this.hasQMI = false;

    var viewLookupTable = {
        "home-gallery": $(".home-gallery"),
        "qmi-gallery":  $(".qmi-gallery"),
        "home-grid": $(".home-grid"), 
        "qmi-grid": $(".qmi-grid")
    }
    
    var filteredViewLookupTable = {
        "home-gallery": $(".filtered-home-gallery"),
        "qmi-gallery":  $(".filtered-qmi-gallery"),
        "home-grid": $(".filtered-home-grid"), 
        "qmi-grid": $(".filtered-qmi-grid")
    }

    var filteredCardsLookupTable = {
        "exact-match": {
            "home-gallery" : [],
            "qmi-gallery" :  [],
            "home-grid" : [], 
            "qmi-grid" : []
        },
        "close-match": {
            "home-gallery" : [],
            "qmi-gallery" :  [],
            "home-grid" : [], 
            "qmi-grid" : []
        }
    }

    var communityID = $("#HomeDesignSummary").data('communityId');
    var plans;
    var qmiPlans;

    if(communityID) {        
        this.getCommunityPlans = function() {
            $.ajax({
                type: 'GET',
                context: this,
                url: '/api/plan/homeplans?communityId=' + communityID,
                // Uncomment Line below to run Frontend on localhost
                // url: 'https://cdk.dev.pulte.com/api/plan/homeplans?communityId=' + communityID, 
                success: function(response) {
                    plans = response;
                }
            });
        }

        this.getQMIPlans = function() {
            $.ajax({
                type: 'GET',
                context: this,
                url: '/api/plan/qmiplans?communityId=' + communityID,
                // Uncomment Line below to run Frontend on localhost
                // url: 'https://cdk.dev.pulte.com/api/plan/homeplans?communityId=' + communityID,
                success: function(response) {
                    qmiPlans = response;
                }
            });
        }
    }

    this.initialize = function (element, options) {    
        _this = this;

        // See More toggle for QMIs
        $('.more-button').click(function() {
            var socialLinksSection = $(this).parent()
                                            .parent()
                                            .parent()
                                            .siblings('.QMIGalleryCard__carousel')
                                            .children('.PlanGridCard__social-links');
            if($(this).parent().hasClass('collapse-more')) {
                $(this).parent().removeClass('collapse-more');
                $(this).text('See Less'); 
                socialLinksSection.addClass('top-align');       
            }
            else {
                $(this).parent().addClass('collapse-more');
                $(this).text('See More');
                socialLinksSection.removeClass('top-align');      
            }
        });

        // ...More toggle for plans
        var moreOptions = moreToggle.getOptions(this.$element);
        moreToggle.initialize(moreOptions);

        if(communityID) {    
            this.getCommunityPlans();
            this.getQMIPlans();
        }

        this.$element.on("hdf-view-change", this.handleView.bind(this));
        this.$element.on("hdf-filter-change", this.handleFilter.bind(this));

        var homeGalleryFilteredTable = filteredViewLookupTable['home-gallery'];
        var homePlansCount = homeGalleryFilteredTable.find('.HomeDesignSummary__card-container section.plan-summary-gallery').length;

        var qmiGalleryFilteredTable = filteredViewLookupTable['qmi-gallery'];
        var qmiPlansCount = qmiGalleryFilteredTable.find('.QMIGalleryCard').length;

        this.hasQMI = qmiPlansCount > 0;  
        
        ($(window).outerWidth() < 1025) ? this.handleView(null,"home-grid",false) : this.handleView(null,"home-gallery",false);
        
        var newModel = new viewModel();
        this.koModel = newModel;
        ko.applyBindings(newModel,element);

        this.resetFilter();
        $('#HomeDesignFilter').trigger('home-design-initialized', [homePlansCount, qmiPlansCount]);

        var defaultToQMI = parseInt(this.$element.data('defaultToQmi'));

        if(defaultToQMI > 0){
            $("#r-QMIs, label[for='r-QMIs']").trigger("click")
        } else {
            $("#r-Homes, label[for='r-Homes']").trigger("click")
        }
        
    };

    this.handleFilter = function(event, planOptions, isFiltered, currentView, filterKoModel) {
        // Remove Event Listeners for Compare module
        $(".Compare-icon").off();
        $(".remove-icon").off();

        if(!isFiltered) {
            this.resetFilter();        
            this.handleView(null,currentView,false,filterKoModel);
            return;
        } 

        
        $('.filtered-list').removeClass('hide');

        homeGalleryPlanCards = $.merge(filteredCardsLookupTable['exact-match']['home-gallery'],filteredCardsLookupTable['close-match']['home-gallery']);
        homeGridPlanCards = $.merge(filteredCardsLookupTable['exact-match']['home-grid'],filteredCardsLookupTable['close-match']['home-grid']);
        qmiGalleryPlanCards = $.merge(filteredCardsLookupTable['exact-match']['qmi-gallery'],filteredCardsLookupTable['close-match']['qmi-gallery']);
        qmiGridPlanCards = $.merge(filteredCardsLookupTable['exact-match']['qmi-grid'],filteredCardsLookupTable['close-match']['qmi-grid']);
        
        homeGalleryPlanCards = homeGalleryPlanCards.sort(function(a,b) { return $(a).data('seriesSortOrder') - $(b).data('seriesSortOrder')});
        homeGridPlanCards = homeGridPlanCards.sort(function(a,b) { return $(a).data('seriesSortOrder') - $(b).data('seriesSortOrder')});
        qmiGalleryPlanCards = qmiGalleryPlanCards.sort(function(a,b) { return $(a).data('seriesSortOrder') - $(b).data('seriesSortOrder')});
        qmiGridPlanCards = qmiGridPlanCards.sort(function(a,b) { return $(a).data('seriesSortOrder') - $(b).data('seriesSortOrder')});

        Object.values(viewLookupTable).forEach(function(val){val.detach()});

        var filteredHomePlanIds = this.getFilteredPlanIds(plans, planOptions);
        var filteredQMIPlanIds = this.getFilteredPlanIds(qmiPlans, planOptions);           

        var homeGalleryFilteredCards = this.getPlanCards(homeGalleryPlanCards, filteredHomePlanIds, true);
        filteredCardsLookupTable['exact-match']['home-gallery'] = $(homeGalleryFilteredCards['exact-match']);
        filteredCardsLookupTable['close-match']['home-gallery'] = $(homeGalleryFilteredCards['close-match']);        

        var homeGridFilteredCards = this.getPlanCards(homeGridPlanCards, filteredHomePlanIds, true);
        filteredCardsLookupTable['exact-match']['home-grid'] = $(homeGridFilteredCards['exact-match']);
        filteredCardsLookupTable['close-match']['home-grid'] = $(homeGridFilteredCards['close-match']);

        var qmiGalleryFilteredCards = this.getPlanCards(qmiGalleryPlanCards, filteredQMIPlanIds, false);
        filteredCardsLookupTable['exact-match']['qmi-gallery'] = $(qmiGalleryFilteredCards['exact-match']);
        filteredCardsLookupTable['close-match']['qmi-gallery'] = $(qmiGalleryFilteredCards['close-match']);
    
        var qmiGridFilteredCards = this.getPlanCards(qmiGridPlanCards, filteredQMIPlanIds, false);
        filteredCardsLookupTable['exact-match']['qmi-grid'] = $(qmiGridFilteredCards['exact-match']);
        filteredCardsLookupTable['close-match']['qmi-grid'] = $(qmiGridFilteredCards['close-match']);
    
        $container.find('#exactMatches').children().detach();
        $container.find('#closeMatches').children().detach();

        var exactMatchCards = filteredCardsLookupTable['exact-match'][currentView];
        $(exactMatchCards).appendTo($container.find('#exactMatches'));

        var closeMatchCards = filteredCardsLookupTable['close-match'][currentView];
        $(closeMatchCards).appendTo($container.find('#closeMatches'));

        this.updateFilterCount(currentView, filterKoModel);
        this.addGridClassToFilterResults(currentView);        
    }

    this.updateFilterCount = function(currentView, filterKoModel) {
        var exactCount = filteredCardsLookupTable['exact-match'][currentView].length;
        var closeCount = filteredCardsLookupTable['close-match'][currentView].length;
    
        $container.find('.exact-title').removeClass('hide');
        if (exactCount < 1) {
            $container.find('.exact-title').addClass('hide');
        }

        $container.find('.close-title').removeClass('hide');
        if (closeCount < 1) {
            $container.find('.close-title').addClass('hide');
        }

        this.koModel.viewObservable.exactFilteredCount(exactCount);
        this.koModel.viewObservable.closeFilteredCount(closeCount);
        
        if (filterKoModel) {
            var homeMatchCount = filteredCardsLookupTable['exact-match']['home-gallery'].length;
            var qmiMatchCount = filteredCardsLookupTable['exact-match']['qmi-gallery'].length;

            if (currentView.includes('home')) {                
                filterKoModel.viewObservable.currentProductTotalCount(filterKoModel.viewObservable.totalHomePlans());
            } else {
                filterKoModel.viewObservable.currentProductTotalCount(filterKoModel.viewObservable.totalQMIPlans());
            }
            filterKoModel.viewObservable.currentProductMatchCount(filteredCardsLookupTable['exact-match'][currentView].length);
            filterKoModel.viewObservable.currentProductNoMatchCount(filteredCardsLookupTable['close-match'][currentView].length);
            filterKoModel.viewObservable.homeMatchCount(homeMatchCount);
            filterKoModel.viewObservable.qmiMatchCount(qmiMatchCount);
        }    
    }

    this.getFilteredPlanIds = function(plans, planOptions) {

        var filteredPlans = $(plans)
            .filter(function(index, plan) {

                var lowPrice = 0; 
                var highPrice = 0; 

                if (!plan.price && plan.comingPriceRange && plan.comingPricePrefix) {
                    
                    var temp = plan.comingPriceRange.replace( /[^0-9.]/g, '');
                    var prefix = parseFloat(temp)
                    var baseTen = plan.comingPriceRange.includes("M") ? 1000000 : 1000;
                    
                    lowPrice = prefix * baseTen; 
                    highPrice = prefix * baseTen; 

                    // Checking if pric is in 1.1M format or 1M format
                    if (plan.comingPriceRange.includes("M") && !plan.comingPriceRange.includes("."))
                    {
                        switch (plan.comingPricePrefix.toLowerCase())
                        {
                            case "low":
                                highPrice = highPrice + 300000; 
                                break;
                            case "mid":
                                lowPrice = lowPrice + 300001; 
                                highPrice = highPrice + 700000; 
                                break;
                            case "upper":
                                lowPrice = lowPrice + 700001; 
                                highPrice = highPrice + 999999; 
                                break;
                        }
                    }
                    else
                    {
                        switch (plan.comingPricePrefix.toLowerCase())
                        {
                            case "low":
                                highPrice = highPrice + 30000; 
                                break;
                            case "mid":
                                lowPrice = lowPrice + 30001; 
                                highPrice = highPrice + 70000; 
                                break;
                            case "upper":
                                lowPrice = lowPrice + 70001; 
                                highPrice = highPrice + 99999; 
                                break;
                        }
                    }

                }
                else{
                    
                    if (plan.finalPrice) {
                        plan.price = plan.finalPrice > 0 ? plan.finalPrice : plan.price;
                    }
                    lowPrice = plan.price; 
                    highPrice = plan.price; 

                }

                if(!plan.planTypeNameActual && plan.plan && plan.plan.planTypeNameActual) {
                    plan.planTypeNameActual = plan.plan.planTypeNameActual;
                } else {
                    if (!plan.planTypeNameActual) {
                        plan.planTypeNameActual = '';
                    }
                }

                if(plan.maxBathrooms) {
                    plan.bathrooms = plan.maxBathrooms;
                }

                if(plan.maxBedrooms) {
                    plan.bedrooms = plan.maxBedrooms;
                }

                var hasSelectedHomeTypes = planOptions['Home Options'] !== null;

                // If both prices are the same use normal comparison, if not compare sliding window based on range
                var priceComparator = highPrice === lowPrice ? ((lowPrice >= planOptions['PR Lower'] && highPrice <= planOptions['PR Upper'])) : ((lowPrice >= planOptions['PR Lower'] || lowPrice >= planOptions['PR Lower']-(highPrice - lowPrice)) && (highPrice <= planOptions['PR Upper'] || highPrice <= planOptions['PR Upper']+(highPrice-lowPrice)))

                return plan.bathrooms >= planOptions['Bathrooms'] 
                    && priceComparator
                    && plan.bedrooms >= planOptions['Bedrooms']
                    && plan.squareFeet >= planOptions['Sqft Lower']
                    && plan.squareFeet <= planOptions['Sqft Upper']
                    && (!hasSelectedHomeTypes || planOptions['Home Options'].includes(plan.planTypeNameActual.toLowerCase()));
            });

        var filteredPlanIds = $.map(filteredPlans, function(plan) {
            return getPlanId(plan)
        });

        return filteredPlanIds;
    }


    this.getPlanCards = function(planCards, filteredPlanIds, homesOrQMIs) {

        var closeMatchPlanCards = [];
        var exactMatchPlanCards = [];
        
        // check on whether we are dealing with qmis or plans
        var exactMatchIds = $(homesOrQMIs ? plans : qmiPlans).filter(function(index, plan){ 
            var id = getPlanId(plan); 
            return filteredPlanIds.includes(id)
        });


        var closeMatchIds = $(homesOrQMIs ? plans : qmiPlans).filter(function(index, plan){ 
            var id = getPlanId(plan); 
            return !filteredPlanIds.includes(id)
        });

        // order is price range, fixed price, then price coming soon
        // after finding exact and close matches, sort, then find corresponding plan cards
        var exactMatchPrices = exactMatchIds.map(mapPlanPriceForSort).get(); 
        var closeMatchPrices = closeMatchIds.map(mapPlanPriceForSort).get(); 

        exactMatchPrices.sort(sortFilteredPlans)
        closeMatchPrices.sort(sortFilteredPlans)

        exactMatchPrices.forEach(function(plan){
            planCards.each(function(index, card){  
                var id = getPlanId(plan); 
                if(id == $(card).data('planId'))
                    exactMatchPlanCards.push(card)
            })

        })

        closeMatchPrices.forEach(function(plan){
            planCards.each(function(index, card){  
                var id = getPlanId(plan); 
                if(id == $(card).data('planId'))
                    closeMatchPlanCards.push(card)
            })
        })

        return {'exact-match': exactMatchPlanCards, 'close-match': closeMatchPlanCards};
    }

    function mapPlanPriceForSort(index, plan){
        var truePrice = 0;
        
        if (!plan.price && plan.comingPriceRange && plan.comingPricePrefix) {
            var temp = plan.comingPriceRange.replace( /[^0-9.]/g, '');
            var prefix = parseFloat(temp)
            var baseTen = plan.comingPriceRange.includes("M") ? 1000000 : 1000;
            truePrice = prefix * baseTen; 
                // Checking if pric is in 1.1M format or 1M format
            //  Take lowest price only
            if (plan.comingPriceRange.includes("M") && !plan.comingPriceRange.includes("."))
            {
                switch (plan.comingPricePrefix.toLowerCase())
                {
                    case "mid":
                        truePrice = truePrice + 300001; 
                        break;
                    case "upper":
                        truePrice = truePrice + 700001; 
                        break;
                }
            }
            else
            {
                switch (plan.comingPricePrefix.toLowerCase())
                {
                    case "mid":
                        truePrice = truePrice + 30001; 
                        break;
                    case "upper":
                        truePrice = truePrice + 70001; 
                        break;
                }
            }
            
        } else {

            if (plan.finalPrice) {
                truePrice = plan.finalPrice > 0 ? plan.finalPrice : plan.price;
            }
            else{
                truePrice = plan.price
            }
            
            // call for pricing flag is set or another pricing scenario, should come at end
            if(truePrice == 0){
                truePrice = Number.MAX_SAFE_INTEGER
            }
        }
        
        return {
            id : getPlanId(plan),
            truePrice : truePrice
        }

    }

    function getPlanId(plan){
        if(plan.inventoryHomeID) {
            return plan.inventoryHomeID
        } else if (plan.id) {
            return plan.id;
        } else {
            return plan.plan.id;
        } 
    }

    function sortFilteredPlans(a,b){
        return parseFloat(a.truePrice) - parseFloat(b.truePrice); 
    }

    this.handleView = function(event, view, isFiltered, filterKoModel) {

        // Remove Event Listeners for Compare module
        $(".Compare-icon").off();
        $(".remove-icon").off();

        

        Object.values(viewLookupTable).forEach(function(val){
            val.detach()
        });

        $container.find('#exactMatches, #closeMatches').children().detach();

        $('.filtered-list').removeClass('hide');
        var exactMatchCards = filteredCardsLookupTable['exact-match'][view];
        var closeMatchCards = filteredCardsLookupTable['close-match'][view];

        $container.find('#exactMatches').append(exactMatchCards);
        $container.find('#closeMatches').append(closeMatchCards);

        this.addGridClassToFilterResults(view);

        if (filterKoModel) {
            this.updateFilterCount(view, filterKoModel);
        }
        if(!isFiltered) {
            $container.find('.exact-title, .close-title').addClass('hide');
        }

    }


    this.addGridClassToFilterResults = function(view) {
        if(view == 'qmi-grid') {
            if(!$('#closeMatches').hasClass('qmi-grid')) {
                $('#closeMatches').addClass('qmi-grid');
            }
            if(!$('#exactMatches').hasClass('qmi-grid')) {
                $('#exactMatches').addClass('qmi-grid');
            }
        } else {
            $('#closeMatches, #exactMatches').removeClass('qmi-grid');
        }
    }
    this.resetFilter = function() {
        var homeGalleryFilteredTable = filteredViewLookupTable['home-gallery'];
        var homeGalleryPlanCards = homeGalleryFilteredTable.find('.HomeDesignSummary__card-container section.plan-summary-gallery');

        var homeGridFilteredTable = filteredViewLookupTable['home-grid'];
        var homeGridPlanCards = homeGridFilteredTable.find('.HomeDesignSummary__card-container section.PlanGridCard');
                
        var qmiGalleryFilteredTable = filteredViewLookupTable['qmi-gallery'];
        var qmiGalleryPlanCards = qmiGalleryFilteredTable.find('.QMIGalleryCard');

        var qmiGridFilteredTable = filteredViewLookupTable['qmi-grid'];
        var qmiGridPlanCards = qmiGridFilteredTable.find('.QMIGridCard');

        if(homeGalleryPlanCards.length == 0) {
            homeGalleryPlanCards = $.merge(filteredCardsLookupTable['exact-match']['home-gallery'],filteredCardsLookupTable['close-match']['home-gallery']);
            homeGridPlanCards = $.merge(filteredCardsLookupTable['exact-match']['home-grid'],filteredCardsLookupTable['close-match']['home-grid']);
        } else {
            homeGalleryPlanCards.each(function(index) { $(this).data('seriesSortOrder', index) });
            homeGridPlanCards.each(function(index) { $(this).data('seriesSortOrder', index) });
        }

        if(this.hasQMI) {
            if(qmiGalleryPlanCards.length == 0) {
                qmiGalleryPlanCards = $.merge(filteredCardsLookupTable['exact-match']['qmi-gallery'],filteredCardsLookupTable['close-match']['qmi-gallery']);
                qmiGridPlanCards = $.merge(filteredCardsLookupTable['exact-match']['qmi-grid'],filteredCardsLookupTable['close-match']['qmi-grid']);
            } else {
                qmiGalleryPlanCards.each(function(index) { $(this).data('seriesSortOrder', index) });
                qmiGridPlanCards.each(function(index) { $(this).data('seriesSortOrder', index) });
            }
        }

        filteredCardsLookupTable['exact-match']['home-gallery'] = homeGalleryPlanCards;
        filteredCardsLookupTable['exact-match']['home-grid'] = homeGridPlanCards;
        filteredCardsLookupTable['exact-match']['qmi-gallery'] = qmiGalleryPlanCards;
        filteredCardsLookupTable['exact-match']['qmi-grid'] = qmiGridPlanCards;
    
        filteredCardsLookupTable['close-match']['home-gallery'] = [];
        filteredCardsLookupTable['close-match']['home-grid'] = [];
        filteredCardsLookupTable['close-match']['qmi-gallery'] = [];
        filteredCardsLookupTable['close-match']['qmi-grid'] = [];
    }
}

module.exports = component(HomeDesignSummary); 
