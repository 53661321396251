var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');
var analyticsForm = require('../forms/analyticsForm');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');

function GlobalLeadForm() {
  this.maxNumberCommunitiesSelected = 3;
  this.initialize = function (options, element) {
    this.brand = $('body').data('brand');
    this.$statesDropdown = this.$element.find('select[name=state]');
    this.$regionsDropdown = this.$element.find('select[name=region]');
    this.$communitiesDropdown = this.$element.find('select[name=CommunityIds]');
    this.$firstNameInput = this.$element.find('input[name=FirstName]');
    this.$lastNameInput = this.$element.find('input[name=LastName]');
    this.$emailContainer = this.$element.find('.GlobalLeadForm__email-container');
    this.$emailLabel = this.$emailContainer.find('.GlobalLeadForm__email-label');
    this.$emailInput = this.$emailContainer.find('input[name=Email]');
    this.$zipCodeContainer = this.$element.find('.GlobalLeadForm__zipcode-container');
    this.$zipCodeInput = this.$element.find('input[name=ZipCode]');
    this.$isRealtorCheckbox = this.$element.find('input[name=IsRealtor]');
    this.$isRealtorLabel = this.$element.find('.GlobalLeadForm__is-realtor-label');
    this.$realtorInfoContainer = this.$element.find('.GlobalLeadForm__realtor-info-container');
    this.$realtorNameInput = this.$realtorInfoContainer.find('input[name=BrokerOffice]');
    this.$realtorNumberInput = this.$realtorInfoContainer.find('input[name=BrokerId]');
    this.$edhRegionIdInput = this.$element.find('.Field-edhregionid');
    this.userIsAuthenticated = this.$element.data('user-is-authenticated');
    this.isInlineForm = this.$element.hasClass("GlobalLeadForm--inline");
    this.formType = this.isInlineForm ? AdobeLaunch.FORM_INLINE_TYPE : AdobeLaunch.FORM_MODAL_TYPE;
    this.isCreateAccountForm = false;
    this.isLoginForm = false;

    this.directionsMapModalVersion = this.$element.data('directionsMapVersion');

    this.$communityInfoSelectionContainer = this.$element.find('.GlobalLeadForm__community-info-selection-container');
    this.$regionThankYouContainer = this.$element.find('.GlobalLeadForm__region-thank-you-container');

    this.$regionHeadline = this.$regionThankYouContainer.find('.GlobalLeadForm__region-headline');
    this.$regionDescription = this.$regionThankYouContainer.find('.GlobalLeadForm__region-description');
    this.$seeCommunitiesButton = this.$regionThankYouContainer.find('.GlobalLeadForm__see-communities-button');

    this.$singleCommunityThankYouContainer = this.$element.find('.GlobalLeadForm__single-community-thank-you-container');

    this.$singleCommunityNameContainer = this.$singleCommunityThankYouContainer.find('.GlobalLeadForm__single-community-name');
    this.$singleCommunityPhoneNumberCta = this.$singleCommunityThankYouContainer.find('.GlobalLeadForm__single-community-phone-number-cta');
    this.$singleCommunityGetDirectionsCta = this.$singleCommunityThankYouContainer.find('.GlobalLeadForm__single-community-get-directions-cta');
    this.$singleCommunityHoursContainer = this.$singleCommunityThankYouContainer.find('.GlobalLeadForm__single-community-hours');

    this.$singleCommunitySpecialNoteContainer = this.$singleCommunityHoursContainer.find('.GlobalLeadForm__single-community-special-note');

    // detaching these elements from the DOM and storing them for later
    this.$singleCommunityHoursDayOfWeekContainer = this.$singleCommunityHoursContainer.find('.GlobalLeadForm__single-community-hours-day-of-week').detach();
    this.$singleCommunityHoursDisplayTextContainer = this.$singleCommunityHoursContainer.find('.GlobalLeadForm__single-community-hours-display-text').detach();

    this.$multipleCommunitiesThankYouContainer = this.$element.find('.GlobalLeadForm__multiple-communities-thank-you-container');

    // detaching this element from the DOM and storing it for later
    this.$multipleCommunityContainer = this.$multipleCommunitiesThankYouContainer.find('.GlobalLeadForm__community-multiple').detach();

    this.$rightPanelContainer = this.$element.find('.GlobalLeadForm__right-panel-container');
    this.$customerInfoSelectionContainer = this.$element.find('.GlobalLeadForm__customer-info-selection-container');
    this.$customerInfoThankYouContainer = this.$element.find('.GlobalLeadForm__customer-info-thank-you-container');

    this.$customerThankYouMessageContainer = this.$customerInfoThankYouContainer.find('.GlobalLeadForm__customer-thank-you-message-container');
    this.$customerThankYouMessageHeadline = this.$customerThankYouMessageContainer.find('.GlobalLeadForm__customer-thank-you-headline');
    this.$customerThankYouMessageDescription = this.$customerThankYouMessageContainer.find('.GlobalLeadForm__customer-thank-you-message');

    this.$realtorThankYouMessageContainer = this.$customerInfoThankYouContainer.find('.GlobalLeadForm__realtor-thank-you-message-container');
    this.$realtorThankYouMessageHeadline = this.$realtorThankYouMessageContainer.find('.GlobalLeadForm__realtor-thank-you-headline');
    this.$realtorThankYouMessageDescription = this.$realtorThankYouMessageContainer.find('.GlobalLeadForm__realtor-thank-you-message');

    this.$doneButtonContainer = this.$customerInfoThankYouContainer.find('.GlobalLeadForm__done-button-container')

    this.$createAccountContainer = this.$customerInfoThankYouContainer.find('.GlobalLeadForm__create-account-container');

    this.$createAccountButtonContainer = this.$createAccountContainer.find('.GlobalLeadForm__create-account-submit-button-container');
    this.$createAccountpasswordInput = this.$createAccountContainer.find('input[name=Password]');
    this.$createAccountPasswordVerifyInput = this.$createAccountContainer.find('input[name=PasswordVerify]');
    this.$createAccountLabel = this.$createAccountContainer.find('.GlobalLeadForm__create-account-label');

    this.$loginContainer = this.$customerInfoThankYouContainer.find('.GlobalLeadForm__login-container');

    this.$loginButtonContainer = this.$loginContainer.find('.GlobalLeadForm__login-submit-button-container');
    this.$loginPasswordInput = this.$loginContainer.find('input[name=password]');
    this.$loginRememberMeInput = this.$loginContainer.find('input[name=rememberMe]');
    this.$loginHeader = this.$loginContainer.find('.GlobalLeadForm__login-header');

    this.$availableHoursToggleButtons = this.$element.find('.GlobalLeadForm__single-community-ctas.community__availableHoursToggle');
    this.$communityHoursContainer = this.$element.find('.GlobalLeadForm__single-community-hours');
    this.$submitButton = this.$element.find('.GlobalLeadForm__submit-button');
    this.$element
      .attach(Form)
      .on(constants.FORM_AJAX_SUBMIT, this.handleFormSubmission.bind(this))
      .on(constants.FORM_CHANGED, this.handleFormChanged.bind(this))
      .on(constants.REGION_SELECTED, this.populateCommunities.bind(this));
    this.$customerInfoSelectionContainer.find('.GlobalLeadForm__submit-button').prop('disabled', true);

    // listen for changes in state and region dropdowns to populate regions and communities respectively
    this.$statesDropdown.change(this.populateRegions.bind(this));
    this.$regionsDropdown.change(this.populateCommunities.bind(this));
    this.$communitiesDropdown.change(this.onCommunitySelectChange.bind(this));

    // listen for changes to isRealtor checkbox
    this.$isRealtorCheckbox.change(this.toggleRealtorInfoElements.bind(this));

    // Shows community hours on mobile
    this.$availableHoursToggleButtons.click(this.toggleMobileCommunityAvailableHours.bind(this));

    this.currentCommunities = [];
  }

  this.onCommunitySelectChange = function (e) {
    //get the list of selected options
    var selectedOptions = this.$communitiesDropdown.prop('selectedOptions');
    var count = selectedOptions.length;

    // if 3 communities are already selected stop the selection of a 4th
    if (count > this.maxNumberCommunitiesSelected) {
      this.$communitiesDropdown.val(this.lastValidSelection); // set the val to the 3 selected options
    } else {
      this.lastValidSelection = this.$communitiesDropdown.val(); // update the selected options if less than 3 were already selected
    }
  }

  this.populateRegions = function () {
    this.$regionsDropdown.prop('disabled', true);
    this.$communitiesDropdown.prop('disabled', true);
    this.$submitButton.prop('disabled', true);

    this.resetCommunitiesDropdown();

    var statesDropdownValue = this.$statesDropdown.val();

    if (statesDropdownValue) {
      $.ajax({
        url: '/API/Region/Search',
        context: this,
        data: {
          State: statesDropdownValue,
          Brand: window.Pulte.Brand ? window.Pulte.Brand.replace("-", " ") : 'Pulte'
        },
        dataType: "json",
        success: this.onRegionsFetchSuccess,
        error: this.onError,
        complete: this.resetCommunitiesDropdown
      });
    }
  }

  this.onRegionsFetchSuccess = function (data) {
    var _this = this;
    var $defaultOption = this.$regionsDropdown.children().first().clone();

    this.$regionsDropdown.empty();

    $defaultOption.appendTo(this.$regionsDropdown);

    if (data && data.length) {
      data.forEach(function (region) {
        var option = document.createElement('option');

        option.value = region.Name;
        option.text = region.Name;

        _this.$regionsDropdown.append(option);
      });

      this.$regionsDropdown.prop('disabled', false);

      return;
    }
    return onError(null, 'Unknown Error');
  };

  this.populateCommunities = function () {
    this.$communitiesDropdown.prop('disabled', true);

    if (this.$communitiesDropdown.prop('required')) {
      this.$submitButton.prop('disabled', true);
    }

    var statesDropdownValue = this.$statesDropdown.val();
    var regionsDropdownValue = this.$regionsDropdown.val();

    if (statesDropdownValue && regionsDropdownValue) {
      $.ajax({
        url: '/API/Community/Search',
        context: this,
        data: {
          State: statesDropdownValue,
          Region: regionsDropdownValue,
          Brand: window.Pulte.Brand ? window.Pulte.Brand.replace("-", " ") : 'Pulte',
          JsonFormat: true,
          pageSize: 0
        },
        dataType: "json",
        success: this.onCommunitiesFetchSuccess,
        error: this.onError
      });
    }
  }

  this.onCommunitiesFetchSuccess = function (data) {
    var _this = this;

    this.resetCommunitiesDropdown();

    this.currentCommunities = data;

    if (data && data.length) {
      data.forEach(function (community) {
        var option = document.createElement('option');

        option.value = community.id;
        option.text = community.name;

        _this.$communitiesDropdown.append(option);
      });

      //When Communities reloads, ensure the submit button is disabled again if needed
      if (this.$submitButton.is(':enabled') && this.$communitiesDropdown.is(':required')) {
        this.$submitButton.prop('disabled', true);
      }

      this.$communitiesDropdown.prop('disabled', false);
      return;
    }

    return this.onError(null, "Unknown Error");
  };

  this.resetCommunitiesDropdown = function () {
    var $defaultOption = this.$communitiesDropdown.children().first().clone();

    this.$communitiesDropdown.empty();

    $defaultOption.appendTo(this.$communitiesDropdown);
  }

  this.toggleRealtorInfoElements = function () {
    var $form = this.$isRealtorCheckbox.closest('form');
    if (this.$isRealtorCheckbox.is(':checked')) {
      this.$submitButton.prop('disabled', true);
      this.$isRealtorLabel.addClass('d-none');
      this.$realtorInfoContainer.removeClass('d-none');
      this.$realtorNameInput.prop('disabled', false);
      this.$realtorNumberInput.prop('disabled', false);
      this.$realtorNameInput.focus();
    } else {
      this.$realtorNumberInput.parent().removeClass('has-error').removeClass('is-empty');
      this.$realtorNameInput.parent().removeClass('has-error').removeClass('is-empty');
      this.$isRealtorLabel.removeClass('d-none');
      this.$realtorInfoContainer.addClass('d-none');
      this.$realtorNameInput.prop('disabled', true);
      this.$realtorNumberInput.prop('disabled', true);
    }

      if ($form.is(':valid')) {
        this.$submitButton.prop('disabled', false);
      }
    }

  this.handleFormChanged = function () {
    this.$element.off(constants.FORM_CHANGED);
    AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_GLOBAL_ID, this.formType, AdobeLaunch.FORM_REQUEST_INFO_NAME);
  };

  this.handleFormSubmission = function (event, form) {
    if(!form.data.IsRealtor) {
      form.data.BrokerOffice = '';
      form.data.BrokerId = '';            
    }

    $.ajax({
      method: form.options.method,
      url: form.options.action,
      data: form.data,
      dataType: "json",
      context: this,
      success: function (response) {
        if (this.isCreateAccountForm || this.isLoginForm) {
          if (response.Success && response.UserId) {
            if (this.isCreateAccountForm) {
              AdobeLaunch.pushUserEvent(AdobeLaunch.USER_REGISTERED_EVENT_NAME);
            }
            else {
              AdobeLaunch.pushUserEvent(AdobeLaunch.USER_SIGNED_IN_EVENT_NAME, response.UserId);
            }
          }
          // redirect the user after account creation
          location.href = response.RedirectUrl;
        } else if (response.success) {
          // show thank you messaging and forms if successful
          this.onLeadFormSubmissionSuccess(form.data, response.userExists);
        }

        analyticsForm.RewriteObjectForm(response, event);
      },
      error: this.onError
    });
  }

  this.onError = function (data, textStatus, event) {
    analyticsForm.RewriteObjectForm(data, event);
    AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, this.formType, AdobeLaunch.FORM_REQUEST_INFO_NAME);
  }

  this.onLeadFormSubmissionSuccess = function (formData, userExists) {
    if (formData) {
      // hide community and customer info selection containers
      this.$communityInfoSelectionContainer.addClass('d-none');
      this.$customerInfoSelectionContainer.addClass('d-none');

      if (this.userIsAuthenticated) {
        this.$rightPanelContainer.addClass('GlobalLeadForm__vertically-centered')
        this.$doneButtonContainer.removeClass('d-none');
        this.$doneButtonContainer.addClass('d-flex');
      } else {
        if (userExists) {
          this.convertFormToLogin();
        } else {
          this.convertFormToCreateAccount();
        }
      }

      var isRegionLead = false;
      var edhRegionId = this.$edhRegionIdInput.val();      

      if (formData.CommunityIds && formData.CommunityIds.length > 1) {
        AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, this.formType, AdobeLaunch.FORM_REQUEST_INFO_NAME, formData.CommunityIds, null, edhRegionId);

        // populate and display community info thank you container with multiple communities info
        this.populateMultipleCommunitiesThankYou(formData.CommunityIds);
        this.$multipleCommunitiesThankYouContainer.removeClass('d-none');
      }
      else if (formData.CommunityIds && formData.CommunityIds.length) {
        AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, this.formType, AdobeLaunch.FORM_REQUEST_INFO_NAME, formData.CommunityIds, null, edhRegionId);

        // populate community info thank you container with single community info
        this.populateSingleCommunityThankYou(formData.CommunityIds);
        this.$singleCommunityThankYouContainer.removeClass('d-none');
      }
      else {
        isRegionLead = true;
        AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, this.formType, AdobeLaunch.FORM_REQUEST_INFO_NAME, null, null, edhRegionId);

        // populate and display community info thank you container with region info if user did not select a community
        this.populateRegionThankYou(formData);
        this.$regionThankYouContainer.removeClass('d-none');
      }

      this.setupThankYouMessage(formData.IsRealtor, isRegionLead, userExists);
      // show customer info thank you container      
      this.$customerInfoThankYouContainer.removeClass('d-none');
      this.showTopOfForm();
    }
  }

  this.showTopOfForm = function () {
    this.$element[0].scrollIntoView({
      block: "start"
    });

    // So Global Nav won't cover the community name on mobile.
    window.scrollBy(0, -50);
  }

  this.replaceFirstNameMergeField = function ($headlineElement) {
    var _this = this;

    // replace merge field for customer first name in headline element
    $headlineElement.text(function (index, text) {
      if (text.indexOf('{firstName}')) {
        text = text.replace('{firstName}', _this.$firstNameInput.val());
      }

      return text;
    });
  }

  this.replaceRegionMergeField = function ($element, isRegionLead) {
    var innerHTML = $element.prop('innerHTML');

    if (innerHTML.indexOf('{regionName}')) {
      if (isRegionLead && this.$regionsDropdown.val() != null) {
        innerHTML = innerHTML.replace('{regionName}', this.$regionsDropdown.val());
      } else {
        innerHTML = innerHTML.replace('{regionName}', '');
      }
    }

    $element.prop('innerHTML', innerHTML);
  }

  this.convertFormToCreateAccount = function () {
    // change name and action of form to create account
    this.$element.prop('name', '');
    this.$element.prop('action', '/formhandling/account/createaccount');

    // disable validated fields that aren't necessary for account creation to prevent validation
    this.$statesDropdown.prop('disabled', true);
    this.$regionsDropdown.prop('disabled', true);

    // move email field to the thank-you-container and make it readonly
    this.setupEmailField(this.$createAccountLabel, this.$emailLabel.data('create-account-email-label'));

    // enable customer info thank you password fields
    this.$createAccountpasswordInput.prop('disabled', false);
    this.$createAccountPasswordVerifyInput.prop('disabled', false);

    // show the create account container
    this.$createAccountContainer.removeClass('d-none');

    this.isCreateAccountForm = true;
  }

  this.convertFormToLogin = function () {
    // change name and action of form to login
    this.$element.prop('name', '');
    this.$element.prop('action', '/formhandling/account/login');

    // disable validated fields that aren't necessary for login to prevent validation
    this.$statesDropdown.prop('disabled', true);
    this.$regionsDropdown.prop('disabled', true);
    this.$firstNameInput.prop('disabled', true);
    this.$lastNameInput.prop('disabled', true);
    this.$zipCodeInput.prop('disabled', true);

    // move email field to the thank-you-container and make it readonly
    this.setupEmailField(this.$loginHeader, this.$emailLabel.data('login-email-label'));

    // change email input name to username
    this.$emailInput.prop('name', 'username');

    // enable customer info thank you login password field and remember me checkbox
    this.$loginPasswordInput.prop('disabled', false);
    this.$loginRememberMeInput.prop('disabled', false);

    // edit names of create account and login password inputs
    this.$createAccountpasswordInput.prop('name', 'CreateAccountPassword');

    // show the login container
    this.$loginContainer.removeClass('d-none');

    this.isLoginForm = true;
  }

  this.setupEmailField = function ($elementBefore, emailLabel) {
    $elementBefore.after(this.$emailContainer);
    this.$emailInput.prop('readonly', true);
    var inputValue = this.$emailInput.prop('value');
    this.$emailLabel.prop('innerHTML', '<strong>' + emailLabel + '</strong>' + this.$emailInput.prop('outerHTML'));
    this.$emailLabel.find('input').prop('value', inputValue);
  }

  this.setupThankYouMessage = function (isRealtor, isRegionLead, userExists) {
    if (isRealtor) {
      this.$realtorThankYouMessageContainer.removeClass('d-none');
      this.$customerThankYouMessageContainer.addClass('d-none');

      this.selectThankYouText(this.$realtorThankYouMessageHeadline, userExists);
      this.selectThankYouText(this.$realtorThankYouMessageDescription, userExists);
      this.replaceFirstNameMergeField(this.$realtorThankYouMessageHeadline);
      this.replaceRegionMergeField(this.$realtorThankYouMessageHeadline, isRegionLead);
      this.replaceRegionMergeField(this.$realtorThankYouMessageDescription, isRegionLead);

    } else {
      this.$customerThankYouMessageContainer.removeClass('d-none');
      this.$realtorThankYouMessageContainer.addClass('d-none');

      this.selectThankYouText(this.$customerThankYouMessageHeadline, userExists);
      this.selectThankYouText(this.$customerThankYouMessageDescription, userExists);
      this.replaceFirstNameMergeField(this.$customerThankYouMessageHeadline);
      this.replaceRegionMergeField(this.$customerThankYouMessageHeadline, isRegionLead);
      this.replaceRegionMergeField(this.$customerThankYouMessageDescription, isRegionLead);
    }
  }

  this.selectThankYouText = function ($thankYouElement, isExistingUser) {
    var existingUserText = $thankYouElement.data("existing-user-text");

    if (!this.userIsAuthenticated && isExistingUser) {
      $thankYouElement.prop('innerHTML', existingUserText);
    }
  }

  this.populateMultipleCommunitiesThankYou = function (communityIds) {
    var _this = this;
    var matchedCommunitiesCount = 0;

    $.each(communityIds, function (i, communityId) {
      $.each(_this.currentCommunities, function (i, community) {
        // comparing ids with double equals because communityId is a string and community.id is a number
        // limit the number of communities to display to 3
        if (communityId == community.id && matchedCommunitiesCount < 3 && community.address) {
          var $clonedMultipleCommunityContainer = _this.$multipleCommunityContainer.clone();

          // set community specific values for current multiple community container elements
          $clonedMultipleCommunityContainer.find('.GlobalLeadForm__community-multiple-name').text(community.name);
          $clonedMultipleCommunityContainer.find('.GlobalLeadForm__community-multiple-city-state').text(community.address.city + ', ' + community.address.stateAbbreviation);
          $clonedMultipleCommunityContainer.find('.GlobalLeadForm__community-multiple-cta').attr('href', community.pageURL);

          // append the cloned element to the dom and display it to the user
          _this.$multipleCommunitiesThankYouContainer.append($clonedMultipleCommunityContainer);

          matchedCommunitiesCount++;

          // break out of the current loop since we found a match already
          return false;
        }
      });
    });
  }

  this.populateSingleCommunityThankYou = function (communityId) {
    var _this = this;
    if (communityId && this.currentCommunities) {
      // find the matching community and set the name, phone number cta, and get directions cta
      $.each(this.currentCommunities, function (i, community) {
        if (communityId == community.id) {
          _this.$element.attr('data-analytics-community-id', community.id);
          _this.$element.attr('data-analytics-community-brand', community.brandName);
          _this.$singleCommunityNameContainer.text(community.name);
          _this.$singleCommunityPhoneNumberCta.attr('href', 'tel:' + community.salesPhoneNumber);
          _this.$singleCommunityPhoneNumberCta.append(community.salesPhoneNumber);
          _this.$singleCommunityGetDirectionsCta.attr('data-target', "." + _this.directionsMapModalVersion + "[data-community-id='" + communityId + "']");

          return false;
        }
      });

      // use the community id to query the community hours info
      $.ajax({
        url: '/API/Community/GetCommunityHours?communityId=' + communityId,
        context: this,
        dataType: "json",
        success: this.onGetCommunityHoursSuccess,
        error: this.onError
      });
    }
  }

  this.populateRegionThankYou = function (formData) {
    var url = '/api/Region/GetRegionInfo?state=' + formData.state + '&region=' + formData.region + '&brand=' + this.brand;
    $.ajax({
      context: this,
      url: url,
      success: function (response) {
        this.$regionHeadline.text(formData.region);
        this.$regionDescription.html(response.Description);
        this.$seeCommunitiesButton.prop('href', response.RelativeUrl);
      }
    });
  }

  this.onGetCommunityHoursSuccess = function (communityHoursObjects) {
    var _this = this;
    var weekDay = new Array(7);
    var opaqueClass = "GlobalLeadForm__date-label-opaque";

    weekDay[0] = "Sunday";
    weekDay[1] = "Monday";
    weekDay[2] = "Tuesday";
    weekDay[3] = "Wednesday";
    weekDay[4] = "Thursday";
    weekDay[5] = "Friday";
    weekDay[6] = "Saturday";

    var today = new Date().getDay();

    if (communityHoursObjects && communityHoursObjects.length) {
      var indexOfLastObject = communityHoursObjects.length - 1;

      $.each(communityHoursObjects, function (i, communityHoursObject) {
        if (i !== indexOfLastObject) {
          // each day of the week starting with Monday
          // clone the data point container element twice
          var $dayOfWeekElement = _this.$singleCommunityHoursDayOfWeekContainer.clone();
          var $hoursElement = _this.$singleCommunityHoursDisplayTextContainer.clone();

          // set the values on the data points
          $dayOfWeekElement.text(communityHoursObject.dayOfWeek);
          $hoursElement.text(communityHoursObject.displayText);

          //If not current day
          if (weekDay[today] !== communityHoursObject.dayOfWeek) {
            $dayOfWeekElement.addClass(opaqueClass);
            $hoursElement.addClass(opaqueClass);
          }

          // add the data points just before the special notes container
          _this.$singleCommunitySpecialNoteContainer.before($dayOfWeekElement);
          _this.$singleCommunitySpecialNoteContainer.before($hoursElement);

        } else {
          // it's the special note if it's the last item in the array
          _this.$singleCommunitySpecialNoteContainer.text(communityHoursObject.displayText);
        }
      });
    }
  }

  this.toggleMobileCommunityAvailableHours = function () {
    this.$availableHoursToggleButtons.toggleClass('d-none');
    this.$communityHoursContainer.toggle('fast');
  }
}

module.exports = component(GlobalLeadForm);
