var component = require('../../../lib/js/component.js');
var Form = require('../forms/form.js');
var constants = require('../forms/constants.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var utils = require('../../../lib/js/utils.js');

function EditMyAccount() {
  this.initialize = function (element, options) {
    var _this = this,
      $uploadPhotoInput = this.$element.find('.EditMyAccount__upload-photo-input'),
      $profileImageResetConfirm = this.$element.find('.EditMyAccount__reset-profile-image-confirm');

    this.$accountInformationForm = this.$element.find('.EditMyAccount__account-information-form');
    this.$passwordUpdateForm = this.$element.find('.EditMyAccount__password-update-form');
    this.$profileImage = this.$element.find('.EditMyAccount__profile-image');
    this.$defaultImageContainer = this.$element.find('.EditMyAccount__default-image-container');
    this.$uploadPhotoLink = this.$element.find('.EditMyAccount__upload-photo-link');
    this.$profileImageResetCTA = this.$element.find('.EditMyAccount__profile-image-reset');
    this.$profileImageResetModal = this.$element.find('.EditMyAccount__reset-profile-image-modal');
    this.$messageBanner = this.$element.prev('.MsgModal');
    this.uploadPhotoCTAText = this.$element.data('upload-photo-cta-text');
    this.updatePhotoCTAText = this.$element.data('update-photo-cta-text');
    this.$uploadPhotoLinkText = this.$uploadPhotoLink.find('.EditMyAccount__upload-photo-link-text');
    this.updateAccountSuccessMessage = this.$accountInformationForm.data('update-account-success-message');
    this.updateAccountDefaultError = this.$accountInformationForm.data('update-account-default-error');
    this.resetImageError = this.$element.data('profile-image-reset-error');
    this.resetImageSuccessMessage = this.$element.data('profile-image-reset-success-message');
    this.uploadImageError = this.$element.data('profile-image-upload-error');
    this.uploadImageSuccessMessage = this.$element.data('profile-image-upload-success-message');
    this.updatePasswordSuccessMessage = this.$passwordUpdateForm.data("update-password-success-message");
    this.updatePasswordFailureMessage = this.$passwordUpdateForm.data("update-password-default-error-message");
    this.$updatePasswordInformationMessage = this.$passwordUpdateForm.find('.EditMyAccount__password-update-information')
    this.$accountInformationMessage = this.$accountInformationForm.find('.EditMyAccount__account-information-message');

    // Home registration initialize
    this.$homeRegistrationForm = this.$element.find('.EditMyAccount__home-registration-form');
    this.$homeRegistrationForm.attach(Form).on(constants.FORM_AJAX_SUBMIT, this.handleHomeRegistrationFormSubmission.bind(this));
    this.$homeRegistrationForm.on(constants.FORM_CHANGED, this.handleHomeRegistrationFormChange.bind(this));
    this.$homeRegistrationMessage = this.$homeRegistrationForm.find('.EditMyAccount__home-registration-message');
    this.$homeRegistrationSuccessMessage = this.$homeRegistrationForm.data('register-home-success-message');
    this.$homeRegistrationErrorMessage = this.$homeRegistrationForm.data('register-home-error-message');

    // Hide messages
    this.$updatePasswordInformationMessage.hide();
    this.$accountInformationMessage.hide();
    this.$homeRegistrationMessage.hide();

    this.$uploadPhotoLink.on('click touch', function (e) {
      e.preventDefault(); // prevent click event from scrolling to top of page
      $uploadPhotoInput.click();
    });

    $uploadPhotoInput.on('change', this.uploadImage.bind(this));

    this.$profileImageResetCTA.on('click touch', function (e) {
      e.preventDefault(); // prevent click event from scrolling to top of page
      _this.$profileImageResetModal.modal('show');
    });

    $profileImageResetConfirm.on('click touch', this.resetProfileImage.bind(this));

    this.$accountInformationForm.attach(Form).on(constants.FORM_AJAX_SUBMIT, this.handleUpdateAccountFormSubmission.bind(this));
    this.$accountInformationForm.on(constants.FORM_CHANGED, this.handleAccountInfoFormChange.bind(this));
    this.$passwordUpdateForm.attach(Form).on(constants.FORM_AJAX_SUBMIT, this.handleUpdatePasswordFormSubmission.bind(this));
    this.$passwordUpdateForm.on(constants.FORM_CHANGED, this.handleUpdatePasswordFormChange.bind(this));

    utils.handleVisible(this.$passwordUpdateForm, function () {
      AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_UPDATE_PASSWORD_NAME);
    });

    utils.handleVisible(this.$accountInformationForm, function () {
      AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_UPDATE_ACCOUNT_INFO_NAME);
    });

    utils.handleVisible(this.$homeRegistrationForm, function () {
      AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
    });
  }

  this.uploadImage = function (e) {
    this.hideBannerAndMessages();

    if (e && e.target && e.target.files && e.target.files.length) {
      var uploadProfilePictureUrl = window.location.origin + '/api/accountapi/UploadProfilePicture';
      var formData = new FormData();
      var imageData = e.target.files[0];
      formData.append('UploadedProfilePicture', imageData);

      $.ajax({
        context: this,
        url: uploadProfilePictureUrl,
        type: 'POST',
        contentType: false,
        processData: false,
        data: formData,
        success: function (response) {
          if (response && response.success) {
            this.updateImageSrc(imageData);
            this.showSuccessMessage(this.uploadImageSuccessMessage);
            AdobeLaunch.pushUserEvent(AdobeLaunch.FORM_PROFILE_PICTURE_UPLOADED);
          } else {
            this.onFailedImageUpload(response);
          }
        },
        error: this.onFailedImageUpload
      });
    }
  }

  this.onFailedImageUpload = function (response) {
    this.showErrorMessage(response, this.uploadImageError);
  }

  this.updateImageSrc = function (imageData) {
    if (imageData) {
      var _this = this,
        fileReader = new FileReader();

      fileReader.onloadend = function () {
        // update the profile picture for the user on the front end
        _this.$profileImage.attr('style', "background-image: url('" + fileReader.result + "');");
        _this.$profileImage.removeClass('d-none');
        _this.$defaultImageContainer.addClass('d-none');
        _this.$profileImageResetCTA.removeClass('d-none');
        _this.$profileImageResetCTA.addClass('d-flex');
        _this.$uploadPhotoLinkText.text(function () {
          return _this.updatePhotoCTAText;
        });
      }

      fileReader.readAsDataURL(imageData);
    }
  }

  this.resetProfileImage = function () {
    this.$messageBanner.hide();

    var resetProfilePictureUrl = window.location.origin + '/api/accountapi/ResetProfilePicture';
    var formData = new FormData();

    this.hideBannerAndMessages();

    $.ajax({
      context: this,
      url: resetProfilePictureUrl,
      type: 'POST',
      contentType: false,
      processData: false,
      data: formData,
      success: function (response) {
        if (response && response.success) {
          this.resetImageSrc();
          this.showSuccessMessage(this.resetImageSuccessMessage);
        } else {
          this.onFailedImageReset(response);
        }
      },
      error: this.onFailedImageReset
    });
  }

  this.resetImageSrc = function () {
    var _this = this;
    this.$profileImage.attr('style', '');
    this.$profileImage.addClass('d-none');
    this.$defaultImageContainer.removeClass('d-none');
    this.$profileImageResetModal.modal('hide');
    this.$profileImageResetCTA.addClass('d-none');
    this.$profileImageResetCTA.removeClass('d-flex');
    this.$uploadPhotoLinkText.text(function () {
      return _this.uploadPhotoCTAText;
    });
  }

  this.onFailedImageReset = function (response) {
    this.showErrorMessage(response, this.resetImageError);
    this.$profileImageResetModal.modal('hide');
  }

  this.setErrorBanner = function () {
    this.$messageBanner.removeClass('MsgModal--success');
    this.$messageBanner.addClass('MsgModal--error');
  }

  this.setSuccessBanner = function () {
    this.$messageBanner.removeClass('MsgModal--error');
    this.$messageBanner.addClass('MsgModal--success');
  }

  this.handleUpdatePasswordFormSubmission = function (event, form) {
    // hide any of the success/failure messages
    this.hideBannerAndMessages();

    $.ajax({
      method: form.options.method,
      url: form.options.action,
      data: form.data,
      dataType: "json",
      context: this,
      success: function (response) {
        if (response && response.Success) {
          AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_UPDATE_PASSWORD_NAME);
          this.showSuccessMessage(this.updatePasswordSuccessMessage);
          this.$updatePasswordInformationMessage.removeClass(constants.MESSAGE_ERROR_CLASS);
          this.$updatePasswordInformationMessage.addClass(constants.MESSAGE_SUCCESS_CLASS);
          this.$updatePasswordInformationMessage.html(this.updatePasswordSuccessMessage)
          this.$updatePasswordInformationMessage.show();
          // clear the form
          this.$passwordUpdateForm.trigger('reset');
        } else {
          this.onFailedUpdatePassword(response)
        }
      },
      error: this.onFailedUpdatePassword
    })
  }

  this.handleUpdateAccountFormSubmission = function (event, form) {
    // hide any of the success/failure messages
    this.hideBannerAndMessages();

    $.ajax({
      method: form.options.method,
      url: form.options.action,
      data: form.data,
      dataType: "json",
      context: this,
      success: function (response) {
        if (response && response.Success) {
          AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_UPDATE_ACCOUNT_INFO_NAME);
          // successful response. Show success message
          this.showSuccessMessage(this.updateAccountSuccessMessage);
          this.$accountInformationMessage.removeClass(constants.MESSAGE_ERROR_CLASS);
          this.$accountInformationMessage.addClass(constants.MESSAGE_SUCCESS_CLASS);
          this.$accountInformationMessage.html(this.updateAccountSuccessMessage);
          this.$accountInformationMessage.show();
        } else {
          // did not receive a valid response. Show error message
          this.onFailedUpdateAccount(response);
        }
      },
      error: this.onFailedUpdateAccount
    });
  }

  this.handleHomeRegistrationFormSubmission = function (event, form) {
    this.hideBannerAndMessages();

    $.ajax({
      method: form.options.method,
      url: form.options.action,
      data: form.data,
      dataType: "json",
      context: this,
      success: this.onSuccessfulHomeRegistration,
      error: this.onFailedHomeRegistration
    });
  }

  this.onSuccessfulHomeRegistration = function (response, status, xhr) {
    if (response && response.Success) {
      AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
      this.showSuccessMessage(this.$homeRegistrationSuccessMessage);
      this.$homeRegistrationMessage.removeClass(constants.MESSAGE_ERROR_CLASS);
      this.$homeRegistrationMessage.addClass(constants.MESSAGE_SUCCESS_CLASS);
      this.$homeRegistrationMessage.html(this.$homeRegistrationSuccessMessage);
      this.$homeRegistrationMessage.show();
    }
    else {
      this.onFailedHomeRegistration(response);
    }
  }

  this.onFailedHomeRegistration = function (response) {
    this.showErrorMessage(response, this.$homeRegistrationErrorMessage);

    this.$homeRegistrationMessage.removeClass(constants.MESSAGE_SUCCESS_CLASS);
    this.$homeRegistrationMessage.addClass(constants.MESSAGE_ERROR_CLASS);
    this.$homeRegistrationMessage.html(this.$homeRegistrationErrorMessage);
    this.$homeRegistrationMessage.show();
    AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
  }

  this.hideBannerAndMessages = function () {
    this.$messageBanner.hide();
    this.$accountInformationMessage.hide();
    this.$updatePasswordInformationMessage.hide();
    this.$homeRegistrationMessage.hide();
  }

  this.onFailedUpdateAccount = function (response) {
    this.showErrorMessage(response, this.updateAccountDefaultError);
    this.showUpdateAccountErrorMessage(response, this.updateAccountDefaultError);
    AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_UPDATE_ACCOUNT_INFO_NAME);
  }

  this.showUpdateAccountErrorMessage = function (response, defaultMessage) {
    this.$accountInformationMessage.removeClass(constants.MESSAGE_SUCCESS_CLASS);
    this.$accountInformationMessage.addClass(constants.MESSAGE_ERROR_CLASS);
    this.$accountInformationMessage.html(response && response.ErrorMessage ? response.ErrorMessage : defaultMessage);
    this.$accountInformationMessage.show();
  }

  this.onFailedUpdatePassword = function (response) {
    this.showErrorMessage(response, this.updatePasswordFailureMessage);
    this.showUpdatePasswordErrorMessage(response, this.updatePasswordFailureMessage)
    AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_UPDATE_PASSWORD_NAME);
  }

  this.showUpdatePasswordErrorMessage = function (response, defaultMessage) {
    this.$updatePasswordInformationMessage.removeClass(constants.MESSAGE_SUCCESS_CLASS);
    this.$updatePasswordInformationMessage.addClass(constants.MESSAGE_ERROR_CLASS);
    this.$updatePasswordInformationMessage.html(response && response.ErrorMessage ? response.ErrorMessage : defaultMessage)
    this.$updatePasswordInformationMessage.show();
  }

  this.showErrorMessage = function (response, defaultMessage) {
    this.setErrorBanner();
    this.$messageBanner.html(response && response.ErrorMessage ? response.ErrorMessage : defaultMessage);
    this.$messageBanner.show();
  }

  this.showSuccessMessage = function (successMessage) {
    this.setSuccessBanner();
    this.$messageBanner.html(successMessage);
    this.$messageBanner.show();
  }

  this.handleHomeRegistrationFormChange = function () {
    this.$homeRegistrationForm.off(constants.FORM_CHANGED);
    AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_HOME_REGISTRATION_NAME);
  }

  this.handleAccountInfoFormChange = function () {
    this.$accountInformationForm.off(constants.FORM_CHANGED);
    AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_UPDATE_ACCOUNT_INFO_NAME);
  }

  this.handleUpdatePasswordFormChange = function () {
    this.$passwordUpdateForm.off(constants.FORM_CHANGED);
    AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_GLOBAL_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_UPDATE_PASSWORD_NAME);
  }
}

module.exports = component(EditMyAccount);
