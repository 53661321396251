var component = require('../../../lib/js/component.js');
var AdobeLaunch = require('../../../lib/js/adobelaunch.js');
var Form = require('../../components/forms/form.js');
var constants = require('../../components/forms/constants.js');
var CommunityLeadForm = require('../../components/community-lead-form/community-lead-form');
var analyticsForm = require('../../components/forms/analyticsForm.js');
var utils = require('../../../lib/js/utils.js');

function CommunityInlineLeadForm(){    
    this.initialize = function (element) {   
        utils.handleVisible(this.$element, function() {
            AdobeLaunch.pushFormViewedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_INLINE_TYPE, 
                AdobeLaunch.FORM_REQUEST_INFO_NAME);
        });
        this.$inLineFormContainer = this.$element;
        this.isLoginForm = false;
        this.userIsAuthenticated = false;
        this.isCreateAccountForm = false;
        var $leadForm = this.$element.find('form[name=CommunityLeadForm]');
        this.$customerInfoContainer = this.$element.find('.CommunityLeadForm__customer-info-selection-container');       
        userIsAuthenticated = $leadForm.data('user-is-authenticated');        
        this.$realtorInfoContainer = $leadForm.find('.CommunityLeadForm__realtor-info-container');
        this.$realtorNameInput = this.$realtorInfoContainer.find('.CommunityLeadForm__BrokerOffice');
        this.$realtorNumberInput = this.$realtorInfoContainer.find('.CommunityLeadForm__BrokerId');
        this.$isRealtorCheckbox = this.$element.find('.CommunityLeadForm__is-realtor-checkbox');
        $leadForm.attach(Form);         
        $leadForm.on(constants.FORM_CHANGED, this.handleFormChanged.bind(this));           
        $leadForm.on(constants.FORM_AJAX_SUBMIT, this.handleSubmit.bind(this));
        this.$leadForm = $leadForm;
        this.$isRealtorCheckbox.on('change', this.isRealtorToggle.bind(this));         
        this.$communityForm = this.$element.find('.CommunityLeadForm');
        this.$communityForm.attach(CommunityLeadForm);
        this.$doneButtonContainer = this.$communityForm.find('.CommunityLeadForm__done-button-container');
        this.$createAccountContainer = this.find('.CommunityLeadForm__create-account-container');
        this.$createAccountPasswordField = this.$createAccountContainer.find('input[name=Password]');
        this.$createAccountPasswordVerifyField = this.$createAccountContainer.find('input[name=PasswordVerify]');
        // show the main right panel when the modal opens
        this.$customerInfoContainer.removeClass('d-none');
        this.isRealtorToggle();        
    }

    this.handleFormChanged = function () {
        this.$leadForm.off(constants.FORM_CHANGED);
        AdobeLaunch.pushFormStartedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_INLINE_TYPE, 
            AdobeLaunch.FORM_REQUEST_INFO_NAME);
    };

    this.handleSubmit = function (event, form) {
        if(this.isLoginForm){
            form.data.username = form.data.Email;
        }
        
        if(!form.data.IsRealtor) {
            form.data.BrokerOffice = '';
            form.data.BrokerId = '';            
        }

        $.ajax({
            method: form.options.method,
            url: form.options.action,
            data: form.data,
            dataType: "json",
            context: this,
            success: function (response) {
                if (this.isCreateAccountForm || this.isLoginForm) {
                    if (response.Success && response.UserId) {
                        if(this.isCreateAccountForm) {
                            AdobeLaunch.pushUserEvent(AdobeLaunch.USER_REGISTERED_EVENT_NAME);
                        } else {
                            AdobeLaunch.pushUserEvent(AdobeLaunch.USER_SIGNED_IN_EVENT_NAME, 
                                response.UserId);
                        }
                    }              
                    location.href = response.RedirectUrl;                   
                }
                else if (response.success){
                    // show thank you messaging and forms if successful
                    this.formSubmissionSuccess(form, response.userExists);
                }
                analyticsForm.RewriteObjectForm(response, event);
            },
            error: this.onError
        });
    }

    this.onError = function (data, textStatus, event) {
        analyticsForm.RewriteObjectForm(data, event);
        AdobeLaunch.pushFormSubmitFailedEvent(AdobeLaunch.FORM_COMMUNITY_ID, AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME);
    }

    this.replaceFirstNameMergeField = function($headlineElement) {
        var $firstNameInput = this.find('.CommunityLeadForm__firstNameInput');
        
        // replace merge field for customer first name in headline element
        $headlineElement.text(function (index, text) {
            if (text.indexOf('{firstName}') > -1) {
                text = text.replace('{firstName}', $firstNameInput.val());
            }

            return text;
        });
    }

    this.isRealtorToggle = function () {               
        
        var isRealtor = this.$isRealtorCheckbox.is(':checked');
        var $form = this.$isRealtorCheckbox.closest('form');
        var $submit = $form.find('[type="submit"]');        

        if (isRealtor) {
            $submit.prop('disabled', true);
            $form.find('.CommunityLeadForm__is-realtor-label').addClass('d-none');
            this.$realtorInfoContainer.removeClass('d-none');
            this.$realtorNameInput.prop('disabled', false);
            this.$realtorNumberInput.prop('disabled', false);
            this.$realtorNameInput.focus();

        } else {
            $form.find('.CommunityLeadForm__is-realtor-label').removeClass('d-none');
            this.$realtorInfoContainer.addClass('d-none');            
            this.$realtorNameInput.prop('disabled', true);
            this.$realtorNameInput.parent().removeClass('is-empty').removeClass('has-error');
            this.$realtorNumberInput.prop('disabled', true);
            this.$realtorNumberInput.parent().removeClass('is-empty').removeClass('has-error');
        }   

        if($form.is(':valid')){
            $submit.prop('disabled', false);
        }
    }

    this.selectThankYouText = function ($thankYouElement, userIsAuthenticated, isExistingUser) {
        var existingUserText = $thankYouElement.data("existing-user-text");    
        if(!userIsAuthenticated && isExistingUser) {
            $thankYouElement.prop('innerHTML', existingUserText);
        }
    }

    this.setupThankYouMessage = function (userIsAuthenticated, isExistingUser) {
        // unhide the thank you message container
        this.find('.CommunityLeadForm__customer-info-thank-you-container').removeClass('d-none');

        // Show Customer/Realtor Thank You Message based off isRealtorCheckbox
        var isRealtor = this.find('.CommunityLeadForm__is-realtor-checkbox').is(":checked")
        if (isRealtor) {
            this.find('.CommunityLeadForm__realtor-thank-you-message-container').removeClass('d-none');
            var $realtorThankYouMessageHeadline = this.find('.CommunityLeadForm__realtor-thank-you-headline');
            this.selectThankYouText($realtorThankYouMessageHeadline, userIsAuthenticated, isExistingUser);
            this.replaceFirstNameMergeField($realtorThankYouMessageHeadline);
            var $realtorThankYouMessage = this.find('.CommunityLeadForm__realtor-thank-you-message');
            this.selectThankYouText($realtorThankYouMessage, userIsAuthenticated, isExistingUser);
        } else {       
            this.find('.CommunityLeadForm__customer-thank-you-message-container').removeClass('d-none');
            var $customerThankYouMessageHeadline = this.find('.CommunityLeadForm__customer-thank-you-headline');
            this.selectThankYouText($customerThankYouMessageHeadline, userIsAuthenticated, isExistingUser);
            this.replaceFirstNameMergeField($customerThankYouMessageHeadline);
            var $customerThankYouMessage = this.find('.CommunityLeadForm__customer-thank-you-message');
            this.selectThankYouText($customerThankYouMessage, userIsAuthenticated, isExistingUser);
        }
    }  

    this.convertFormToCreateAccount = function () {
        // change name and action of form to create account
        var $leadform = this.find('form[name=CommunityLeadForm]');
        $leadform.prop('name', '');
        $leadform.prop('action', '/formhandling/account/createaccount');

        this.moveEmailField(this.find('.CommunityLeadForm__create-account-label'));

        // enable customer info thank you password fields
        this.$createAccountPasswordField.prop('disabled', false);
        this.$createAccountPasswordVerifyField.prop('disabled', false);

        // edit name of create account password input
        this.$createAccountPasswordField.prop('name', 'password');

        // show the create account container
        this.$createAccountContainer.removeClass('d-none');
        this.isCreateAccountForm = true;
    }   

    this.convertFormToLogin = function () {
        var $leadform = this.find('form[name=CommunityLeadForm]');
        $leadform.prop('name', '');
        $leadform.prop('action', '/formhandling/account/login');

        this.moveEmailField(this.find('.CommunityLeadForm__log-in-prompt'));
        var $loginContainer = this.find('.CommunityLeadForm__log-in-container');
        $loginContainer.find('input[name=Password]').prop('disabled', false);
        $loginContainer.find('input[name=RememberMe]').prop('disabled', false);
        $loginContainer.removeClass('d-none');

        // change Create Account Password field name so it doesn't match Login Password field name
        this.$createAccountPasswordField.prop('name', 'CreateAccountPassword');

        this.isLoginForm = true;
    }    

    // move email field to the thank-you-container and make it readonly
    this.moveEmailField = function ($fieldBefore) {
        var $leadForm = this.find('.CommunityLeadForm') 
        var $emailContainer = $leadForm.find('.CommunityLeadForm__email-container');
        var $emailInput = $emailContainer.find('input[name=Email]')
        $fieldBefore.after($emailContainer);
        $emailInput.prop('readonly', true);
    }

    this.formSubmissionSuccess = function (form, isExistingUser){       
        if (form.data) {
            AdobeLaunch.pushFormSubmitSucceededEvent(AdobeLaunch.FORM_COMMUNITY_ID, 
                AdobeLaunch.FORM_INLINE_TYPE, AdobeLaunch.FORM_REQUEST_INFO_NAME, form.data.CommunityIds, 
                form.data.CommunityBrand);
            this.$customerInfoContainer.addClass('d-none');
            this.setupThankYouMessage(userIsAuthenticated, isExistingUser);
            if (!userIsAuthenticated) {
                if(isExistingUser) {
                    this.convertFormToLogin();
                } else {
                    this.convertFormToCreateAccount();
                }
            } else {
                //unhide the modal done button if the user is already logged in
                this.$doneButtonContainer.removeClass('d-none');
                this.$doneButtonContainer.addClass('d-flex');
                this.$communityForm.addClass('align-items-center');
            }
        }
    } 
}

module.exports = component(CommunityInlineLeadForm);
