// registerLoginEditorial - Used to Register User LoginEditorial
// loginLoginEditorial - Used to Login User LoginEditorial
function registerLoginEditorial() {
    var email = document.getElementById("txtLoginEditorialEmail").value;
    var firstName = document.getElementById("txtLoginEditorialFirstName").value;
    var lastName = document.getElementById("txtLoginEditorialLastName").value;
    var phone = document.getElementById("txtLoginEditorialPhone").value;
    var zipcode = document.getElementById("txtLoginEditorialZipCode").value;
    var country = document.getElementById("txtLoginEditorialCountry").value;
    var sentIdentity = {
        "channel": "WEB",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phoneNumbers": [phone],
        "street": ["", ""],
        "postCode": zipcode,
        "country": country,
        "type": "IDENTITY",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pos": window._boxever_settings.pointOfSale,
        "browser_id": Boxever.getID(),
        "identifiers": [{
            "provider": "email",
            "id": email
        }]
    };
    Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
}
var btnRegisterLoginEditorial = document.getElementById('btnLoginEditorialRegister');
if (btnRegisterLoginEditorial) {
    btnRegisterLoginEditorial.addEventListener('click', registerLoginEditorial, false);
}

function loginLoginEditorial() {
    var email = document.getElementById("txtLoginEditorialLoginEmail").value;
    var sentIdentity = {
        "channel": "WEB",
        "type": "IDENTITY",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pos": window._boxever_settings.pointOfSale,
        "browser_id": Boxever.getID(),
        "identifiers": [{
            "provider": "email",
            "id": email
        }]
    };
    Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
}
var btnLoginLoginEditorial = document.getElementById('btnLoginEditorialLogin');
if (btnLoginLoginEditorial) {
    btnLoginLoginEditorial.addEventListener('click', loginLoginEditorial, false);
}


// submitCommunitySpecific - Used to send Communities RequestInfo Forms
function submitCommunitySpecific() {
    var email = document.getElementById("txtCommunitySpecificEmail").value;
    var firstName = document.getElementById("txtCommunitySpecificFirstName").value;
    var lastName = document.getElementById("txtCommunitySpecificLastName").value;
    var phone = document.getElementById("txtCommunitySpecificPhone").value;
    var zipcode = document.getElementById("txtCommunitySpecificZipCode").value;
    var country = document.getElementById("txtCommunitySpecificCountry").value;
    var sentIdentity = {
        "channel": "WEB",
        "firstName": firstName,
        "lastName": lastName,
        "phoneNumbers": [phone],
        "email": email,
        "street": ["", ""],
        "postCode": zipcode,
        "country": country,
        "type": "IDENTITY",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pos": window._boxever_settings.pointOfSale,
        "browser_id": Boxever.getID(),
        "identifiers": [{
            "provider": "email",
            "id": email
        }]
    };
    Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
}
if ($('#btnCommunitySpecific').parents().find('.ContactCommunity--modal')) {
    document.addEventListener("click", function (e) {
        var target = e.target.closest("#btnCommunitySpecific");
        if (target) {
            submitCommunitySpecific();
        }
    });
}


// submitCommunity - Used to send ContactUs Forms
function submitCommunity() {
    var email = document.getElementById("txtCommunityEmail").value;
    var firstName = document.getElementById("txtCommunityFirstName").value;
    var lastName = document.getElementById("txtCommunityLastName").value;
    var phone = document.getElementById("txtCommunityPhone").value;
    var zipcode = document.getElementById("txtCommunityZipCode").value;
    var country = document.getElementById("txtCommunityCountry").value;
    var sentIdentity = {
        "channel": "WEB",
        "firstName": firstName,
        "lastName": lastName,
        "phoneNumbers": [phone],
        "email": email,
        "street": ["", ""],
        "postCode": zipcode,
        "country": country,
        "type": "IDENTITY",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pos": window._boxever_settings.pointOfSale,
        "browser_id": Boxever.getID(),
        "identifiers": [{
            "provider": "email",
            "id": email
        }]
    };
    Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
}
var btnSubmitCommunity = document.getElementById('btnCommunity');
if (btnSubmitCommunity) {
    btnSubmitCommunity.addEventListener('click', submitCommunity, false);
}


// submitGlobalLeadForm - Used to send ContactUs Forms
function submitGlobalLeadForm(event) {
    if (event.currentTarget.id == 'gmlfSubmit') {
        var email = document.getElementById("gmlfEmail").value;
        var firstName = document.getElementById("gmlfFirstName").value;
        var lastName = document.getElementById("gmlfLastName").value;
        var phone = document.getElementById("gmlfPhone").value;
        var zipcode = document.getElementById("gmlfZipCode").value;
        var country = document.getElementById("gmlfCountry").value;
    } else {
        email = document.getElementById("gilfEmail").value;
        firstName = document.getElementById("gilfFirstName").value;
        lastName = document.getElementById("gilfLastName").value;
        phone = document.getElementById("gilfPhone").value;
        zipcode = document.getElementById("gilfZipCode").value;
        country = document.getElementById("gilfCountry").value;
    }

    var sentIdentity = {
        "channel": "WEB",
        "firstName": firstName,
        "lastName": lastName,
        "phoneNumbers": [phone],
        "email": email,
        "street": ["", ""],
        "postCode": zipcode,
        "country": country,
        "type": "IDENTITY",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pos": window._boxever_settings.pointOfSale,
        "browser_id": Boxever.getID(),
        "identifiers": [{
            "provider": "email",
            "id": email
        }]
    };
    Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
}
var btnSubmitGlobalModalLeadForm = document.getElementById('gmlfSubmit');
if (btnSubmitGlobalModalLeadForm) {
    btnSubmitGlobalModalLeadForm.addEventListener('click', submitGlobalLeadForm, false);
}

var btnSubmitGlobalInlineLeadForm = document.getElementById('gilfSubmit');
if (btnSubmitGlobalInlineLeadForm) {
    btnSubmitGlobalInlineLeadForm.addEventListener('click', submitGlobalLeadForm, false);
} 


// submitCreateAccountModal - Used to Register User CreateAccountModal
// submitLoginModal - Used to Login User from LoginModal
function submitCreateAccountModal() {
    var email = document.getElementById("txtCreateAccountModalEmail").value;
    var firstName = document.getElementById("txtCreateAccountModalFirstName").value;
    var lastName = document.getElementById("txtCreateAccountModalLastName").value;
    var phone = document.getElementById("txtCreateAccountModalPhone").value;
    var zipcode = document.getElementById("txtCreateAccountModalZipCode").value;
    var country = document.getElementById("txtCreateAccountModalCountry").value;
    var sentIdentity = {
        "channel": "WEB",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phoneNumbers": [phone],
        "street": ["", ""],
        "postCode": zipcode,
        "country": country,
        "type": "IDENTITY",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pos": window._boxever_settings.pointOfSale,
        "browser_id": Boxever.getID(),
        "identifiers": [{
            "provider": "email",
            "id": email
        }]
    };
    Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
}
var btnSubmitCreateAccountModal = document.getElementById('btnCreateAccountModal');
if (btnSubmitCreateAccountModal) {
    btnSubmitCreateAccountModal.addEventListener('click', submitCreateAccountModal, false);
}

function submitLoginModal() {
    var email = document.getElementById("txtLoginModalEmail").value;
    var sentIdentity = {
        "channel": "WEB",
        "type": "IDENTITY",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pos": window._boxever_settings.pointOfSale,
        "browser_id": Boxever.getID(),
        "identifiers": [{
            "provider": "email",
            "id": email
        }]
    };
    Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
}
var btnSubmitLoginModal = document.getElementById('btnLoginModal');
if (btnSubmitLoginModal) {
    btnSubmitLoginModal.addEventListener('click', submitLoginModal, false);
}


// registerCreateAccount - Used to Register User CreateAccount
// loginCreateAccount - Used to Login User CreateAccount
function registerCreateAccount() {
    var email = document.getElementById("txtCreateAccountEmail").value;
    var firstName = document.getElementById("txtCreateAccountFirstName").value;
    var lastName = document.getElementById("txtCreateAccountLastName").value;
    var phone = document.getElementById("txtCreateAccountPhone").value;
    var zipcode = document.getElementById("txtCreateAccountZipCode").value;
    var country = document.getElementById("txtCreateAccountCountry").value;
    var sentIdentity = {
        "channel": "WEB",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phoneNumbers": [phone],
        "street": ["", ""],
        "postCode": zipcode,
        "country": country,
        "type": "IDENTITY",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pos": window._boxever_settings.pointOfSale,
        "browser_id": Boxever.getID(),
        "identifiers": [{
            "provider": "email",
            "id": email
        }]
    };
    Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
}
var btnRegisterCreateAccount = document.getElementById('btnCreateAccount');
if (btnRegisterCreateAccount) {
    btnRegisterCreateAccount.addEventListener('click', registerCreateAccount, false);
}

function loginCreateAccount() {
    var email = document.getElementById("txtLoginEmail").value;
    var sentIdentity = {
        "channel": "WEB",
        "type": "IDENTITY",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pos": window._boxever_settings.pointOfSale,
        "browser_id": Boxever.getID(),
        "identifiers": [{
            "provider": "email",
            "id": email
        }]
    };
    Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
}
var btnLoginCreateAccount = document.getElementById('btnLogin');
if (btnLoginCreateAccount) {
    btnLoginCreateAccount.addEventListener('click', loginCreateAccount, false);
}


// Used to send Communities RequestInfo Forms Inline
var form = document.getElementById('CommunityContactForm');
var submitButton = document.querySelector('#CommunityContactForm #btnCommunitySpecific');
if (form) {
    form.addEventListener('submit', function (event) {
        event.preventDefault();
        var firstName = document.querySelector("#CommunityContactForm #txtCommunitySpecificFirstName").value;
        var lastName = document.querySelector("#CommunityContactForm #txtCommunitySpecificLastName").value;
        var email = document.querySelector("#CommunityContactForm #txtCommunitySpecificEmail").value;
        var phone = document.querySelector("#CommunityContactForm #txtCommunitySpecificPhone").value;
        var zipcode = document.querySelector("#CommunityContactForm #txtCommunitySpecificZipCode").value;
        var country = document.querySelector("#CommunityContactForm #txtCommunitySpecificCountry").value;
        var sentIdentity = {
            "channel": "WEB",
            "firstName": firstName,
            "lastName": lastName,
            "phoneNumbers": [phone],
            "email": email,
            "street": ["", ""],
            "postCode": zipcode,
            "country": country,
            "type": "IDENTITY",
            "language": "EN",
            "currency": "USD",
            "page": window.location.href,
            "pos": window._boxever_settings.pointOfSale,
            "browser_id": Boxever.getID(),
            "identifiers": [{
                "provider": "email",
                "id": email
            }]
        };
        Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
        submitButton.click();
    });
}


// registerCreateAccountForm - Used to Register User CreateAccountForm
function registerCreateAccountForm() {
    var email = document.getElementById("txtCreateAccountFormEmail").value;
    var firstName = document.getElementById("txtCreateAccountFormFirstName").value;
    var lastName = document.getElementById("txtCreateAccountFormLastName").value;
    var zipcode = document.getElementById("txtCreateAccountFormZipCode").value;
    var country = document.getElementById("txtCreateAccountFormCountry").value;
    var sentIdentity = {
        "channel": "WEB",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "street": ["", ""],
        "postCode": zipcode,
        "country": country,
        "type": "IDENTITY",
        "language": "EN",
        "currency": "USD",
        "page": window.location.href,
        "pos": window._boxever_settings.pointOfSale,
        "browser_id": Boxever.getID(),
        "identifiers": [{
            "provider": "email",
            "id": email
        }]
    };
    Boxever.eventCreate(sentIdentity, function (data) { }, 'json');
}
var btnRegisterCreateAccountForm = document.getElementById('btnCreateAccountForm');
if (btnRegisterCreateAccountForm) {
    btnRegisterCreateAccountForm.addEventListener('click', registerCreateAccountForm, false);
}
